<template>
  <div class="search-customer-container">
    <v-progress-linear
      class="loader"
      color="loaderColor"
      indeterminate
      v-show="isLoading"
    />

    <div class="search-params-section">
      <div class="section-header">
        {{ $t("complaints.searchCustomerParameters").toUpperCase() }}
      </div>

      <div class="search-params">
        <div class="param">
          <div class="label">
            {{ $t("user.firstName") }}
          </div>
          <div class="value">
            {{ (searchParams && searchParams.name) || "-" }}
          </div>
        </div>

        <div class="param">
          <div class="label">
            {{ $t("user.surname") }}
          </div>
          <div class="value">
            {{ (searchParams && searchParams.surname) || "-" }}
          </div>
        </div>

        <div class="param">
          <div class="label">
            {{ $t("user.email") }}
          </div>
          <div class="value">
            {{ (searchParams && searchParams.email) || "-" }}
          </div>
        </div>

        <div class="param">
          <div class="label">
            {{ $t("user.phone") }}
          </div>
          <div class="value">
            {{ (searchParams && searchParams.phone) || "-" }}
          </div>
        </div>
      </div>
    </div>

    <div class="search-results">
      <div class="row header">
        <div class="selection">
          {{ $t("common.goTo") }}
        </div>

        <div class="field header">
          {{ $t("user.firstName") }}
        </div>

        <div class="field header">
          {{ $t("user.surname") }}
        </div>

        <div class="field header">
          {{ $t("user.email") }}
        </div>

        <div class="field header">
          {{ $t("user.phone") }}
        </div>

        <div class="field header">
          {{ $t("user.address") }}
        </div>
      </div>

      <div v-if="noParameters" class="message">
        {{ $t("ccIntegration.noParameters") }}
      </div>

      <div v-else-if="searchResults.length === 0" class="message">
        {{ $t("ccIntegration.noResults") }}
      </div>

      <div v-else>
        <div v-for="item in searchResults" :key="item.id" class="row">
          <div class="selection button">
            <v-btn rounded text icon @click="selectCustomer(item.id)">
              <IconBox color="iconButton"> stem-logout </IconBox>
            </v-btn>
          </div>

          <div class="field">
            {{ item.firstName }}
          </div>

          <div class="field">
            {{ item.surname }}
          </div>

          <div class="field">
            {{
              item.email && item.email.length > 0 ? item.email.join(", ") : null
            }}
          </div>

          <div class="field">
            {{
              item.phone && item.phone.length > 0 ? item.phone.join(", ") : null
            }}
          </div>

          <div class="field">
            {{ item.address }}
          </div>
        </div>

        <div class="pagination">
          <v-pagination
            color="textColor"
            :value="selectedPage"
            :length="totalPages"
            @input="onPageChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EmitInfo } from "@/eventBus";
import CrmCustomersService from "@/services/CrmCustomersService";

export default {
  name: "SelectSearchedCustomerComplaints",
  props: {},
  data() {
    return {
      searchParams: null,
      searchResults: [],
      selectedPage: null,
      totalPages: null,
      isLoading: false,
      noParameters: false,
    };
  },
  mounted() {
    let isAnyParameter = false;
    const parameters = {};
    const query = this.$route.query;

    Object.keys(query).forEach((item) => {
      if (item && query[item]) {
        isAnyParameter = true;
        parameters[item] = query[item];
      }
    });

    if (isAnyParameter) {
      this.searchParams = parameters;
      this.getCustomer(1);
    } else {
      this.noParameters = true;
    }
  },
  methods: {
    getCustomer(page) {
      const data = { ...this.searchParams, pageNumber: page, pageSize: 12 };
      this.isLoading = true;

      CrmCustomersService.GetCrmCustomersAdvancedSearch(data)
        .then((response) => {
          this.searchResults = response.data;
          this.selectedPage = response.pagination.currentPage;
          this.totalPages = response.pagination.totalPages;

          if (response.pagination.totalItems === 1) {
            EmitInfo("Trwa przełączanie");

            this.$router.push({
              name: "customerComplaints",
              params: { initialSelectedCustomer: response.data[0] },
            });
          }
        })
        .finally(() => (this.isLoading = false));
    },
    selectCustomer(selectedCustomerId) {
      const customer = this.searchResults.find(
        (item) => item.id === selectedCustomerId
      );

      this.$router.push({
        name: "customerComplaints",
        params: { initialSelectedCustomer: customer },
      });
    },
    onPageChange(newPage) {
      this.getCustomer(newPage);
    },
  },
};
</script>

<style scoped lang="scss">
.search-customer-container {
  padding: 20px;

  .loader {
    position: absolute;
    top: 0;
    left: 0;
  }

  .search-params-section {
    .section-header {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      color: $text-title;
    }

    .search-params {
      display: flex;
      flex-direction: row;
      padding-bottom: 20px;
      border-bottom: 1px solid $border-line;

      .param {
        display: flex;
        flex-direction: column;
        min-width: 150px;
        max-width: 200px;

        &:not(:first-child) {
          margin-left: 10px;
        }

        .label {
          padding: 10px;
          color: $text-color;
        }

        .value {
          padding: 10px;
          text-align: center;
          background-color: $content-section-background;
        }
      }
    }
  }

  .search-results {
    margin-top: 60px;

    .row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      padding: 10px;

      &:nth-child(even) {
        background-color: $content-section-background;
      }

      .selection {
        flex: 0 0 85px;
        color: $text-title;

        &.button {
          text-align: center;
        }
      }

      &.header {
        color: $text-color;
        margin-bottom: 30px;
      }

      .field {
        flex: 0 0 20%;
        word-break: break-all;

        &:not(:first-child) {
          padding-left: 10px;
        }

        &.header {
          color: $text-title;
        }
      }
    }

    .row:not(.header):hover {
      background-color: $disabled;
    }

    .message {
      text-align: center;
      font-size: 18px;
      margin-top: 20px;
    }

    .pagination {
      margin-top: 30px;
    }
  }
}
</style>
